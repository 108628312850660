import React, { useEffect, useState } from "react";
import { Breadcrumb, Col, Layout, Menu, Row, theme } from "antd";
import LinkedAnimate from "./components/animation/LinkedAnimate";
import { AnimationWrapper } from "react-hover-animation";

import infinPage from './assets/infinPage.svg'
import brokerHome from './assets/brokerHome.svg'
import brokerWallet from './assets/brokerWallet.svg'
import brokerMeta from './assets/brokerMeta.svg'
import jesterPage from './assets/jesterPage.svg'
import edImage from './assets/ed.png'
import mattImage from './assets/matt.png'
import linkedin from './assets/linkedin.png'

const { Header, Content, Footer } = Layout;

const items = new Array(15).fill(null).map((_, index) => ({
    key: index + 1,
    label: `nav ${index + 1}`,
}));

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  }
  
  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  
    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    return windowDimensions;
  }

const App: React.FC = () => {
    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();

    const { height, width } = useWindowDimensions()

    return (
        <Layout>
            <Header
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "white",
                }}
            >
                <p style={{fontSize: 20, fontWeight: 600}}>Ember Developments</p>
            </Header>
            <Content
                style={{
                    padding: "0em",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <div
                    style={{
                        padding: "0em",
                        alignItems: "center",
                        justifyContent: "center",
                        height: 'auto',
                    }}
                >
                    <h1
                        style={{
                            position: "absolute",
                            fontSize: "4em",
                            marginTop: '10%',
                            marginLeft: '40%'
                        }}
                    >
                        We Make Ideas Into Reality
                    </h1>
                    <LinkedAnimate />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'black', padding: '4em', width: 'auto'}}>
                      <h1 style={{fontSize: 60, color: 'white', margin: 0, justifyContent: 'center', alignContent: 'center', paddingBottom: '0.5em'}}>Our Projects</h1>
                        <Row>
                            <Col span={12}>
                        <AnimationWrapper>
                        <img style={{width: '80%'}} src={infinPage} alt="infinPage" onClick={() => window.open('https://infinaccounting.com', '_blank')}/>
                        </AnimationWrapper>
                            </Col>
                            <Col span={12}>
                        <div style={{display: 'flex', flexDirection: 'column'}}>
                        <h3 style={{ fontSize: 30, color: 'white', textAlign: 'right'}}>Infin Accounting</h3>
                        <p style={{color: 'white'}}>Infin Accounting is a startup with the goal of automating the accounting processes for small to medium businesses.</p>
                        <p style={{color: 'white'}}>This was a bootstraped startup, and completed from ideation to production as a solo feat. The project is completely designed, developed and implemented by Edward.
                        The frontend was developed on React Typescript with a NextJS SSR framework, and it was designed in Figma. The backend was implemented using NodeJS Express, with a GraphQL connector using Apollo. Prisma was also integrated with a MySQL database. 
                        There are external API and Webhook calls to Stripe and Plaid. Authentication and all functionality is custom built without external frameworks. The project is hosted on AWS with an encrypted file store management system.</p>
                        </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                        <h1 style={{ color: 'white'}}>Broker Wallet</h1>
                        <p style={{color: 'white'}}>Broker Wallet is a project commissioned by a client to produce a digital wallet that connects to the bank and Meta Trader.</p>
                        <p style={{color: 'white'}}>This was a project completed by both Edward and Matt. This was both an iOS and Android mobile app written in React Native Typescript.
                        The client came to us with an idea of what they wanted, but left us to implement major technical functionalities and design decisions. This project was done in three major stages as the requirements and preferences of the client were unclear.
                        However, we were able to deliver the end product and adapt to overachieve the client's expectations.</p>
                        <p style={{color: 'white'}}>This was a complicated FinTech app as it required to accrue for multiple wallet currencies as well as user level permissions. API connections were built to source financial data, banking information, as well as connect to Meta Trader.
                        The backend was built on Express NodeJS, with a Prisma Apollo framework and GraphQL connector. We used a PostgreSQL database and there were plenty of custom written database algorithims for efficency. The project was hosted on AWS and designed in Figma.</p>
                            </Col>
                            <Col span={12}>
                        {
                            width > 458 && width < 955 && (
                        <div style={{width: '60%', display: 'flex', flexDirection: 'row', alignItems: 'right'}}>
                          <AnimationWrapper>
                            <img style={{height: '30em', marginTop: '2em'}} src={brokerHome} alt="brokerHome"/>
                          </AnimationWrapper>
                          <AnimationWrapper>
                            <img style={{height: '30em', marginTop: '2em'}} src={brokerWallet} alt="brokerWallet"/>
                          </AnimationWrapper>
                          </div>
                            )
                        }
                        {
                            width < 468 && (
                        <div style={{width: '60%', display: 'flex', flexDirection: 'row', alignItems: 'right'}}>
                          <AnimationWrapper>
                            <img style={{height: '20em', marginTop: '2em'}} src={brokerHome} alt="brokerHome"/>
                        </AnimationWrapper>
                                </div>
                            )
                        }
                        {
                            width > 955 && (
                        <div style={{width: '60%', display: 'flex', flexDirection: 'row', alignItems: 'right'}}>
                          <AnimationWrapper>
                            <img style={{height: '30em', marginTop: '2em'}} src={brokerHome} alt="brokerHome"/>
                          </AnimationWrapper>
                          <AnimationWrapper>
                            <img style={{height: '30em', marginTop: '2em'}} src={brokerWallet} alt="brokerWallet"/>
                          </AnimationWrapper>
                          <AnimationWrapper>
                            <img style={{height: '30em', marginTop: '2em'}} src={brokerMeta} alt="brokerMeta"/>
                          </AnimationWrapper>
                          </div>
                            )
                        }
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                        <AnimationWrapper>
                            <video style={{width: '80%', maxHeight: '600px'}} autoPlay={true} loop={true} muted={true} onClick={() => window.open('https://tpmbot.gg', '_blank')}><source src="https://tpmbot.gg/videos/price_lookup.webm" type="video/webm"/></video>
                        </AnimationWrapper>
                            </Col>
                            <Col span={12}>
                        <h1 style={{ color: 'white'}}>Price Master Bot</h1>
                        <p style={{color: 'white'}}>Introducing the Price Master, your Discord ally for Magic: The Gathering. By harnessing image recognition, it swiftly identifies cards in uploaded images and reveals their names along with current market prices. Say goodbye to manual searches and hello to instant insights with the Price Master on your Discord server.</p>
                        <p style={{color: 'white'}}>This was a solo project from ideation to production responsible by Matt. This was written in Python. The Optical Character Recognition algorithim used the open source Yolov7 library. There was also some automation aspects utilizing ChatGPT for this project.</p>
                            </Col>
                        </Row>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white', padding: '4em', width: 'auto', justifyContent: 'center', marginBottom: '3em'}}>
                    <Row>

                <div style={{ display: 'flex', backgroundColor: 'white', width: 'auto', height: 'auto', justifyContent: 'center'}}>
                    <h1>About Us</h1>
                </div>
                    </Row>
                    <Row>
                        <p>At Ember Developments we are not only experienced professionals, but enthusiastic creators. We combine our technical expertise with creative insights to develop the best solutions for our clients. 
                            We not only value technical best practices, but also prioritise efficent perfectionism.
                        </p>
                        <p style={{marginBottom: '4em'}}>
                            We are not limited by technical constraints. Let us know your needs and we will work together to find the best solution for your needs. 
                        </p>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <div style={{display: 'flex', flexDirection: 'column', width: 'auto', height: 'auto', justifyContent: 'center'}}>
                                <div style={{width: 'auto', height: 'auto', justifyContent: 'center', alignContent: 'center'}}>
                                <img style={{ height: '50%', width: '50%'}} src={edImage} alt="edImage"/>
                                </div>
                                <h2>Edward Chu</h2>
                                <img style={{height: '2em', width: '2em'}} src={linkedin} alt="linkedin" onClick={() => window.open('https://www.linkedin.com/in/edwardchu0', '_blank')}/>
                                <p>He is an experienced full stack developer, with a specialization in frontend, backend and database architecture. I combine both creativity and techinical expertise from ideation to completion. With a focus on best practices, he aims to develop things right and efficently. 
                                    With 8 years of experience in tackling large scale monolith projects, he has ample experience in every end of the development spectrum. Throughout his journey he has adapted in both startup and large scale production environments. With his bredth of experience, he can offer the best solution based on your needs. 
                                    Edward is a graduate of the University of British Columbia.
                                </p>
                            </div>
                        </Col>
                        <Col span={12}>
                            <div style={{display: 'flex', flexDirection: 'column', width: 'auto', height: 'auto', justifyContent: 'center'}}>
                                <img style={{height: '50%', width: '50%'}} src={mattImage} alt="mattImage"/>
                                <h2>Matt Felice</h2>
                               <img style={{height: '2em', width: '2em'}} src={linkedin} alt="linkedin" onClick={() => window.open('https://www.linkedin.com/in/ismattfelice', '_blank')}/>
                                <p>As an experienced infrastructure developer with 11 years of experience, I specialize in optimizing and modernizing software architectures to enhance performance, scalability, and maintainability. In my previous role, I played a pivotal role in transitioning our company from a cumbersome PHP monolith to a cutting-edge microservices architecture powered by Node.js. Leveraging my expertise in cloud technologies, particularly Google Cloud Platform (GCP) and Kubernetes, I orchestrated the seamless migration, enabling our applications to scale effortlessly while streamlining development and deployment processes. My passion for innovation and dedication to excellence drive me to continuously push the boundaries of what's possible in software infrastructure.</p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Content>
            <Footer style={{ textAlign: "center" }}>
                <p>Contact us: edwardchu@outlook.com</p>
                Ember Developments ©{new Date().getFullYear()} 
            </Footer>
        </Layout>
    );
};

export default App;
